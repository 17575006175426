.JSONView {
    white-space: pre;
    box-sizing: border-box;
    background-color: #1e1e1e;
    padding: 10px;
    color: #d4d4d4;
    border-radius: 4px;
    border: 1px solid #444;
    margin-top: 5px;
    font-family: "Courier New", Courier, monospace;
    overflow-x: auto;
    max-height: 80vh;
    overflow-y: auto;
    -moz-osx-font-smoothing: auto;
    -webkit-font-smoothing: auto;
  }
  
  .JSONView .key {
    color: #9cdcfe;
  }
  
  .JSONView .string {
    color: #ce9178;
  }
  
  .JSONView .boolean {
    color: #569cd6;
  }
  
  .JSONView .null {
    color: #569cd6;
  }
  
  .JSONView .number {
    color: #b5cea8;
  }